
import React, { Children }   from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { getCmpId } from "./utilities"
import { getLvl, getWho } from "./cpcb"

type Props = {
    children :JSX.Element
}


 export const NrjRequire  = ({children}:Props) : JSX.Element =>{
    const showPageNotFound = <><h4 className="m-3 p-4 text-center font-semibold text-lg"> Page Not Found</h4></>;
    const protectedRoutes: string[] = ["/changePwdRgd", "/changePwdSpcb"]
    
    let cmpid:string = getCmpId();
    let lvl:string = getLvl();
    let who = getWho();
    return children
    if (cmpid && who){
        let cmp : string =cmpid
        if (cmp.length > 4){
            let foundProtectedRoute:boolean = false;
            for(let i = 0; i < protectedRoutes.length; i++){
                if(window.location.pathname == protectedRoutes[i] && lvl != 'CPCB'){
                    foundProtectedRoute = true;
                    break;
                }
            }
            if(foundProtectedRoute){
                <Navigate to ="/login"></Navigate>
            }
            
            return children;
        }
    }
    return <Navigate to ="/login"></Navigate>
}

