
import React, { useState } from 'react'
import logo1 from "../../app/assests/cpcblogo.png";
import { useNavigate } from "react-router";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { getLvl, getWho } from "../../utilities/cpcb";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import KeyIcon from '@mui/icons-material/Key';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import './sidebar.css'

interface Report {
    name: string;
    path: string;
    description: string;
    hide: boolean;
}


const NewSidebar = (props: any) => {

    const navigate = useNavigate();
    const setPageTitle = props.setPageTitle;

    const navigateToPage = (data: string, description: any,) => {
        navigate(data);
        setPageTitle(description);
        // props.toggleSideNavOff();
    };

    const liElementClicked = (path: string, description: string) => {
        if (navigateToPage) {
            navigateToPage(path, description)
        }
    }

    const renderDashboardMenu = () => {
        return (
            <MenuItem icon={<GridViewRoundedIcon />} onClick={() => navigateToPage("/dashboardvb", "Dashboard")}> Dashboard </MenuItem>
        )
    }


    const renderDirectoryItems = (directoryArr: any[], keyword: string, icon: React.ReactNode) => {
        return directoryArr
            .filter((res: any) => res.name.includes(keyword))
            .map((res: any) => (
                !res.hide && (
                    <MenuItem icon={icon} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                        {res.name}
                    </MenuItem>
                )
            ));
    };

    const renderDirectoryMenu = () => {
        return (
            <SubMenu label="Directory" icon={<ReceiptRoundedIcon />}>
                {renderDirectoryItems(props.DirectoryArr, "RD", <TimelineRoundedIcon />)}
                {renderDirectoryItems(props.DirectoryArr, "SPCB", <BubbleChartRoundedIcon />)}
            </SubMenu>
        )
    }

    const renderStaticsItems =(statisticsArrMenu: any[])=>{
        return statisticsArrMenu
        .map((res: any) => (
            !res.hide && (
                <MenuItem icon={<TrendingUpIcon/>} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                    {res.name}
                </MenuItem>
            )
        ));

    }

    const renderBagReportsItems =(bagsReportsItems:any[])=>{
        return bagsReportsItems
        .map((res:any)=>(
            !res.hide && (
                <MenuItem icon={<RestoreFromTrashIcon/>} key={res.name} onClick={()=>liElementClicked(res.path, res.description)}>{res.name}</MenuItem>
            )
        ))
    }

    const renderOpertaionMenu =(operationMenu:any[])=>{
        return operationMenu
        .map((res:any)=>(
            !res.hide && (
                <MenuItem icon={<AssessmentRoundedIcon/>} key={res.name} onClick={()=>liElementClicked(res.path, res.description)}>{res.name}</MenuItem>
            )
        ))
    }

    const renderOperationMenu = () => {
        return (
            <SubMenu label="Operations" icon={<SettingsApplicationsRoundedIcon />}>
                {/* <MenuItem icon={<AssessmentRoundedIcon />}>CBWTF Report</MenuItem>
                <MenuItem icon={<AssessmentRoundedIcon />}>HCF Report</MenuItem> */}
                {renderOpertaionMenu(props.operations)}
                <SubMenu label='Bags Report' icon={<RestoreFromTrashIcon/>}>
                {renderBagReportsItems(props.bagsReport)}
                </SubMenu>
                <SubMenu label="Statistics" icon={<TrendingUpIcon />}>
                {renderStaticsItems(props.statisticsArrMenu)}
                </SubMenu>
            </SubMenu>
        )
    }

    const renderMonitoringMenu = () => {
        return (
            <SubMenu label="Monitoring" icon={<PreviewRoundedIcon />}>
                <SubMenu label="CBWTF Monitoring" icon={<SettingsApplicationsRoundedIcon />}>
                    {renderMonitoringItems(props.monitoringCBWTF, [
                        { keyword: "CBWTF List", icon: <FormatListBulletedRoundedIcon /> },
                        { keyword: "CBWTF Info", icon: <FeedRoundedIcon /> },
                        { keyword: "HCF - CBWTF", icon: <MedicationRoundedIcon /> }
                    ])}
                </SubMenu>
                <SubMenu label="Hcf-Monitoring" icon={<SettingsApplicationsRoundedIcon />}>
                    {renderMonitoringItems(props.monitoringHCF, [
                        { keyword: "Search HCF", icon: <ManageSearchIcon /> },
                        { keyword: "Find HCF (by mobile)", icon: <FindInPageRoundedIcon /> },
                        { keyword: "Wrong Registering Attempt", icon: <RuleRoundedIcon /> },
                        { keyword: "HCF List", icon: <FormatListBulletedRoundedIcon /> }
                    ])}
                    <MenuItem icon={<TrendingUpIcon />}>Statistics</MenuItem>
                </SubMenu>
            </SubMenu>
        )
    }

    const renderMonitoringItems = (reports: any[], items: { keyword: string, icon: React.ReactNode }[]) => {
        return items.map(({ keyword, icon }) => (
            reports
                .filter((res: any) => res.name.includes(keyword))
                .map((res: any) => (
                    !res.hide && (
                        <MenuItem icon={icon} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                            {res.name}
                        </MenuItem>
                    )
                ))
        ));
    };


    const renderARSubMenu = (reports: Report[], label: string, keywords: string[]) => {
        return (
            <SubMenu label={label} icon={<EventAvailableRoundedIcon />}>
                {keywords.map(keyword =>
                    reports
                        .filter(res => res.name.includes(keyword))
                        .map(res => (
                            !res.hide && (
                                <MenuItem icon={<DateRangeRoundedIcon />} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                                    {res.name}
                                </MenuItem>
                            )
                        ))
                )}
            </SubMenu>
        );
    };

    const renderAnnualReports = () => {
        return (
            <>  <SubMenu label={"Annual Reports"} icon={<EventAvailableRoundedIcon />}>
                {renderARSubMenu(props.annualFormReport, "AR", ["SPCB: Annual Report Form", "HCF Annual Report", "CBWTF Annual Report","HCF Annual Report Show Form","SPCB: Annual Report Fill Form"])}
                {renderARSubMenu(props.monthlyFormReport, "MR", ["SPCB: Monthly Report", "HCF: Monthly Report", "CBWTF: Monthly Report","HCF Monthly Report Show Form"])}
            </SubMenu>
            </>
        )
    }

    const renderAdminMenu = () => {
        return (
            <SubMenu label="Admin" icon={<AdminPanelSettingsRoundedIcon />}>
                {props.showProfile.map((res: any) => {
                    if (res.hide) return null;
                    if (res.name.includes("Profile")) {
                        return (
                            <MenuItem icon={<AccountCircleRoundedIcon />} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                                Profile
                            </MenuItem>
                        );
                    }
                    if (res.name.includes("Change Password") && !res.name.includes("RDs") && !res.name.includes("SPCBs")) {
                        return (
                            <MenuItem icon={<KeyIcon />} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                                {res.name}
                            </MenuItem>
                        );
                    }
                    if (res.name.includes("Change Password RDs") && !res.name.includes("SPCBs")) {
                        return (
                            <MenuItem icon={<KeyIcon />} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                                {res.name}
                            </MenuItem>
                        );
                    }
                    if (res.name.includes("Change Password SPCBs") && !res.name.includes("RDs")) {
                        return (
                            <MenuItem icon={<KeyIcon />} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                                {res.name}
                            </MenuItem>
                        );
                    }
                    if (res.name.includes("Block User List")) {
                        return (
                            <MenuItem icon={<BlockRoundedIcon />} key={res.name} onClick={() => liElementClicked(res.path, res.description)}>
                                {res.name}
                            </MenuItem>
                        );
                    }
                    return null;
                })}
            </SubMenu>
        )
    }


    return (
        <div style={{ display: "flex", height: "100vh", maxWidth: '250px' }} onMouseLeave={props.toggleSideNavOff}>
            <Sidebar className="app">
                <Menu>
                    <div className='menu1 flex items-center'>
                            <img src={logo1} alt="Logo" className="w-8 h-8 mr-2" />
                            <span className="text-black font-semibold">Central Pollution Control Board</span>
                    </div>
                    {renderDashboardMenu()}
                    {renderMonitoringMenu()}
                    {renderOperationMenu()}
                    {getLvl() !== 'RGD' && renderAnnualReports()}
                    {renderDirectoryMenu()}
                    {renderAdminMenu()}
                </Menu>
            </Sidebar>
        </div>
    );
};
export default NewSidebar;