import React, { useState } from 'react';

declare type ToasterType = "success" | "error" | "warning";
interface ToasterState {
  message: string[];
  type: ToasterType;
  visible: boolean;
}

const initialToasterState: ToasterState = {
  message: [''],
  type: 'error',
  visible: false,
};

const ToasterContext = React.createContext<{
  showToaster: (message: string[], type: ToasterType) => void;
  hideToaster: () => void;
}>({
  showToaster: () => {},
  hideToaster: () => {},
});

export const ToasterProvider = (props:any) => {
  const [toaster, setToaster] = useState<ToasterState>(initialToasterState);

  const showToaster = (message: string[], type: ToasterType) => {
    setToaster({ message, type, visible: true });
    setTimeout(hideToaster, 4000); // Auto hide after 3 seconds
  };

  const bgColors:any = {
    'success' : "bg-lime-700",
    'error' : "bg-red-700",
    'warning' : "bg-amber-500"
  }
  const hideToaster = () => {
    setToaster(initialToasterState);
  };

  return (
    <ToasterContext.Provider value={{ showToaster, hideToaster }}>
      {props.children}
      {toaster.visible && toaster.message.length && (
        <div className="fixed top-[6px] right-[6px] border rounded-lg z-50">
          <div
            className={"text-white drop-shadow-2xl border rounded-lg pl-4 pr-1 pb-3 pt-1 w-[300px] " + bgColors[toaster.type]} onClick={hideToaster}
          >
            <div className="flex justify-end">
              <div className=" hover:border hover:border-black rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" className="bi bi-x hover:fill-[#000]" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </div>
              
            </div>
            <div>
              <ul className="flex justify-center align-center">
                <div>
                {toaster.message.map((res: any, index: number) => {
                  return (
                    <>
                      <li key={res} className="text-white">
                        {index + 1} : {res}
                      </li>
                    </>
                  );
                })}
                </div>
                
              </ul>
            </div>
          </div>
        </div>
      )}
    </ToasterContext.Provider>
  );
};

export const useToaster = () => React.useContext(ToasterContext);
